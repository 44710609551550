import axios from "axios";
import { ASSET_MODEL_API } from "config/default.config";
import { commonUtils } from "utils/common.utils";
import { IAddDynamicPropertyReq } from "./model-property.interface";

const downloadStaticProperty = async (reference: string) => {
  const response = await axios.get(
    commonUtils.formatString(
      ASSET_MODEL_API.DOWNLOAD_STATIC_PROPERTY + reference
    )
  );
  return response.data;
};

const fetchStaticPropertyDetailsById = async (id: number) => {
  const response = await axios.get(
    commonUtils.formatString(ASSET_MODEL_API.GET_PROPERTY_DETAILS_BY_ID, id)
  );
  return response.data;
};

const addDynamicProperty = async (request: IAddDynamicPropertyReq) => {
  const response = await axios.post(
    ASSET_MODEL_API.ADD_DYNAMIC_PROPERTY,
    request, {
    validateStatus: function (status) {
      return status >= 200 && status < 600; // Treat only 2xx status codes as successful
    }
  });

  console.log(response);
  return response;



};

const fetchAllDynamicProperty = async (modelId: number | undefined) => {
  const response = await axios.get(
    commonUtils.formatString(ASSET_MODEL_API.GET_DYNAMIC_PROPERTY, modelId)
  );
  return response.data;
};

// ---fetchUnit----
const fetchAllUnit = async () => {
  const response = await axios.get(ASSET_MODEL_API.GET_DYNAMIC_PROPERTY_UNIT, {
    params: {
      searchTerm: "",
      page: "0",
      limit: "100",
      sortType: "ASC",
      sortTerm: "name",
    },
  });
  return response.data.data;
};

// const fetchAllUnit = async () => {
//   axios
//     .get(ASSET_MODEL_API.GET_DYNAMIC_PROPERTY_UNIT, {
//       params: {
//         searchTerm: "",
//         page: "0",
//         limit: "100",
//         sortType: "ASC",
//         sortTerm: "name",
//       },
//     })
//     .then((res) => {
//       console.log("-------unitsssServer", res.data.result);
//       return res.data.data;
//     })
//     .catch((err) => console.log(err));
// }

const updateDynamicProperty = async (request: any) => {
  const response = await axios.put(
    commonUtils.formatString(ASSET_MODEL_API.UPDATE_DYNAMIC_PROPERTY),
    request, {
    validateStatus: function (status) {
      return status >= 200 && status < 600; // Treat only 2xx status codes as successful
    }
  });
  return response;
};

const getDynamicPropertyById = async (id: number | undefined) => {
  const response = await axios.get(
    commonUtils.formatString(ASSET_MODEL_API.GET_DYNAMIC_PROPERTY_BY_ID, id)
  );
  return response.data;
};

const deleteDynamicProperty = async (id: number) => {
  const response = await axios.delete(
    commonUtils.formatString(ASSET_MODEL_API.DELETE_DYNAMIC_PROPERTY, id), {
    validateStatus: function (status) {
      return status >= 200 && status < 600; // Treat only 2xx status codes as successful
    }
  });
  console.log(response);
  
  return response;
};

// const fetchFilePropertyDetailsById = async (id: number) => {
//   const response = await axios.get(
//     commonUtils.formatString(ASSET_MODEL_API.GET_PROPERTY_DETAILS_BY_ID, id)
//   );
//   return response.data;
// };

export const modelPropertyService = {
  fetchStaticPropertyDetailsById,
  addDynamicProperty,
  fetchAllDynamicProperty,
  downloadStaticProperty,
  fetchAllUnit,
  updateDynamicProperty,
  getDynamicPropertyById,
  deleteDynamicProperty,
};
