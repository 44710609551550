import PublishConfirmationDialog from "components/dialogs/publish-confirmation.dialog";
import CreateModelScreen, {
  IModelCRUDForm,
} from "modules/asset-model-crud/create-model";
import DeleteModelScreen from "modules/asset-model-crud/delete-model";
import ModelRelationScreen from "modules/model-relation-console/model-relation-console";
import DeleteRelationScreen from "modules/model-relation-crud/delete-relation";
import PropertyConsole from "modules/property-console/property-console";
import TreeConsole from "modules/tree-console/tree-console";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import { assetModelActions } from "store/asset-model/asset-model.action";
import { setHasChange, setIsPublish } from "store/seqr-ops/seqr-ops.slice";
import "./view-console.scss";

function ViewConsole(props: any) {
  const dispatch = useAppDispatch();
  const [showUpdateModel, setShowUpdateModel] = useState(false);
  const theme = useAppSelector((state) => state.seqrOps.theme);
  const [showDeleteModel, setShowDeleteModel] = useState(false);
  const confirmationPopup = PublishConfirmationDialog();
  const navigationState = useAppSelector(
    (state) => state.seqrOps.navigationState
  );
  const loading = useAppSelector((state) => state.seqrOps.loadingState.loading);

  const [showDeleteRelationModel, setShowDeleteRelationModel] = useState<{
    id: number;
    name: string;
    show: boolean;
  }>({
    id: 0,
    name: "",
    show: false,
  });
  const [showRelationModel, setShowRelationModel] = useState(false);
  const [modelDetails, setModelDetails] = useState<IModelCRUDForm | undefined>({
    id: 0,
    name: "",
    isRootNode: false,
    description: "",
    isEquipment: false,
    icon: "",
  });
  const [relationDetails, setRelationDetails] = useState<{
    sourceModelId: number;
    targetModelId: number;
  }>({ sourceModelId: 0, targetModelId: 0 });
  // const [targetId, setTargetId] = useState<{
  //   targetModelId: number;
  // }>({ targetModelId: 0 });
  const selectedModelDetails = useAppSelector(
    (state) => state.assetModels.selectedModel
  );

  const [isLoading, setIsLoading] = useState(false);
  const history = useNavigate();
  const isDirty = useAppSelector((state) => state.seqrOps.dirtyState.isDirty);
  const isPublish = useAppSelector((state) => state.seqrOps.isPublish);
  const hasChange = useAppSelector((state) => state.seqrOps.hasChange);

  // useEffect(() => {
  //   if (isDirty == false && isPublish == true) {
  //     dispatch(setIsPublish(true));
  //     console.log("hi publish is clicked");

  //     handlePublish();
  //   }
  // }, [isDirty, isPublish]);

  const handlePublish = async () => {
    dispatch(setIsPublish(true));
    const queryParams = "publish";
    history(`/?${queryParams}`);
    setShowRelationModel(false);
    // if (localPubishState === true) {
    confirmationPopup.onOpenDialog({
      title: "Publish Confirmation",
      message: "Are you sure want to publish the model?",
      cancelBtn: "No",
      successBtn: "Yes",
      handleClose: async () => {
        confirmationPopup.closeDialog();
        await dispatch(assetModelActions.publishModel(1));
        dispatch(setIsPublish(false));
      },
    });
    // }
  };

  return (
    <>
      <div className="seqrops-view-console-wrapper">
        <div className="container-fluid">
          <div className="seqrops-outer-console-section">
            <div className="d-flex align-items-center  justify-content-between">
              <div className="seqrops-model-name-section">
                <h1>{selectedModelDetails?.name}</h1>
              </div>
              <div className="seqrops-publish-section">
                <button
                  className="seqrops-btn btn-outline me-2"
                  onClick={() => {
                    const queryParams = "create-model";
                    history(`/?${queryParams}`);
                    dispatch(setHasChange(true));
                    dispatch(setHasChange(true));
                    setModelDetails(undefined);
                    if (isDirty === true) {
                      setShowUpdateModel(false);
                    } else {
                      setShowUpdateModel(true);
                    }
                  }}
                >
                  Create Model
                </button>
                {/* <button
                  className="seqrops-btn btn-fill"
                  onClick={() => {
                    handlePublish();
                  }}
                >
                  {isLoading ? <LoadingIcon /> : 'Publish'}
                </button> */}
                <button
                  className="seqrops-btn btn-fill"
                  onClick={() => {
                    handlePublish();
                  }}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <div className="loader-icon">
                      <div className="loader-icon__spinner"></div>
                    </div>
                  ) : (
                    "Publish"
                  )}
                </button>
              </div>
            </div>
            <div className="seqrops-inner-console-section row mt-2">
              <div className="seqrops-tree-console-section col-md-7">
                <TreeConsole
                  modelId={selectedModelDetails?.id}
                  addRelation={(details: {
                    sourceModelId: number;
                    targetModelId: number;
                  }) => {
                    setRelationDetails(details);
                    setShowRelationModel(true);
                  }}
                  deleteRelation={(id: number, name: string) => {
                    setShowDeleteRelationModel({
                      id,
                      name,
                      show: true,
                    });
                  }}
                  updateModel={(details: IModelCRUDForm) => {
                    setModelDetails(details);
                    setShowUpdateModel(true);
                  }}
                  deleteModel={(details: IModelCRUDForm) => {
                    setModelDetails(details);
                    setShowDeleteModel(true);
                  }}
                />
              </div>
              <div
                className="seqrops-property-console-section col-md-5"
                id={theme ? "dark" : "light"}
              >
                <PropertyConsole />
              </div>
            </div>
          </div>
        </div>
      </div>
      <CreateModelScreen
        showModel={showUpdateModel}
        setShowModel={setShowUpdateModel}
        modelDetails={modelDetails}
      ></CreateModelScreen>

      <DeleteModelScreen
        showModel={showDeleteModel}
        setShowModel={setShowDeleteModel}
        modelDetails={modelDetails}
      ></DeleteModelScreen>

      <ModelRelationScreen
        showModel={!isDirty ? showRelationModel : false}
        setShowModel={setShowRelationModel}
        details={relationDetails}
      ></ModelRelationScreen>

      <DeleteRelationScreen
        showModel={showDeleteRelationModel.show}
        setShowModel={(state: boolean) => {
          setShowDeleteRelationModel({
            id: 0,
            name: "",
            show: state,
          });
        }}
        id={showDeleteRelationModel.id}
        name={showDeleteRelationModel.name}
        sourceId={selectedModelDetails?.id || 0}
      ></DeleteRelationScreen>
    </>
  );
}

export default ViewConsole;
