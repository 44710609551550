import axios from "axios";
import { ASSET_MANAGEMENT_API, ASSET_MODEL_API } from "config/default.config";
import { commonUtils } from "utils/common.utils";
import { IAddStaticPropertyReq, IPropertyTypeChangeValidationReq } from "./model-property.interface";

const addStaticProperty = async (request: IAddStaticPropertyReq) => {
  const response = await axios.post(
    ASSET_MODEL_API.ADD_STATIC_PROPERTY,
    request, {
      validateStatus: function (status) {
        return status >= 200 && status < 600; // Treat only 2xx status codes as successful
      }
    });
  return response;
};

const updateStaticProperty = async (request: IAddStaticPropertyReq) => {
  const response = await axios.put(
    commonUtils.formatString(
      ASSET_MODEL_API.UPDATE_STATIC_PROPERTY,
      request.id
    ),
    request, {
      validateStatus: function (status) {
        return status >= 200 && status < 600; // Treat only 2xx status codes as successful
      }
    });
  return response;
};

const deleteStaticProperty = async (id: number) => {
  const response = await axios.delete(
    commonUtils.formatString(ASSET_MODEL_API.DELETE_STATIC_PROPERTY, id)
  );
  return response.data;
};

const fetchAllStaticProperties = async (modelId: number) => {
  const response = await axios.get(
    commonUtils.formatString(ASSET_MODEL_API.GET_ALL_STATIC_PROPERTIES, modelId)
  );
  return response.data;
};

const propertyTypeChangeValidation = async (request: IPropertyTypeChangeValidationReq) => {
  const response = await axios.post(
    ASSET_MANAGEMENT_API.CHECK_PROPERTY_TYPE_CHANGE_API, request);
  return response;
};

export const staticPropertyService = {
  addStaticProperty,
  updateStaticProperty,
  deleteStaticProperty,
  fetchAllStaticProperties,
  propertyTypeChangeValidation,
};
